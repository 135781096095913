import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor() { }

  private backAt:string;
  private isUnderMaintenance: boolean;

  getIsUnderMaintenance(): boolean  {return this.isUnderMaintenance}
  getBackAt(): string {return this.backAt}
  setBackAt(val:string) {this.backAt = val}
  setIsUnderMaintenance(val:boolean) {this.isUnderMaintenance = val}
}