import { ProductType } from '../../../../shared_models/product-type';
import { Basic, IncrementPicker, FixedPrice, ProgramPicker, IncrementUnit } from '../../../../shared_models/device';

export interface GuidObject {
    password: string;
    serial: string;
    used: boolean;
    used2?: boolean;
}

export interface GuidVars {
    // variables used in the guid/serial step of the wizard
    valid: boolean;
    guidObject: GuidObject;
    protocolVersion: string;
    guidValidationMsg: string;
    isR1: boolean;
    relayNumber: number;
    nextStepFired: boolean;
    anyOpenRelay: boolean;
}

export interface ReconfigValues {
    dev_id?: string;
    serial: string;
    password: string;
    relay_number?: number;
    continued_setup?: boolean;
    device?: Basic | IncrementPicker | FixedPrice | ProgramPicker;
}

export interface ProductCategoryVars {
    productType: ProductType;
    goToPricingStepFired: boolean;
}

export interface PricingModelVars {
    pricingModel: PricingModel;
    goToStartSignalStepFired: boolean;
}

export enum Signal {
    Pulse = 'PULSE',
    FullDuration = 'FULL_DURATION',
    UserStartTime = 'USER_START_TIME'
}

export interface StartSignalVars {
    signal: Signal;
    pulseOptions?: IncrementUnit | 'program';
    goToOptionsStepFired: boolean;
}

export interface DetailsVars {
    disableSubmitBtn: boolean;
    formSubmitted: boolean;
}

export enum PricingModel {
    Variable = 'VARIABLE',
    Fixed = 'FIXED'
}
