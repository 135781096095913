export const statesData: Record<string, Record<string, string>>  = {
    US: {
        "AA": "Armed Forces Americas",
        "AE": "Armed Forces Europe",
        "AK": "Alaska",
        "AL": "Alabama",
        "AP": "Armed Forces Pacific",
        "AR": "Arkansas",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DC": "District of Columbia",
        "DE": "Delaware",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "IA": "Iowa",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "MA": "Massachusetts",
        "MD": "Maryland",
        "ME": "Maine",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MO": "Missouri",
        "MS": "Mississippi",
        "MT": "Montana",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UM": "United States Minor Outlying Islands",
        "UT": "Utah",
        "VA": "Virginia",
        "VI": "Virgin Islands U.S.",
        "VT": "Vermont",
        "WA": "Washington",
        "WI": "Wisconsin",
        "WV": "West Virginia",
        "WY": "Wyoming"
    },
    AU: {
        "ACT": "Australian Capital Territory",
        "NSW": "New South Wales",
        "NT": "Northern Territory",
        "QLD": "Queensland",
        "SA": "South Australia",
        "TAS": "Tasmania",
        "VIC": "Victoria",
        "WA": "Western Australia"
    },
    IT: {
        "AG": "Agrigento",
        "AL": "Alessandria",
        "AN": "Ancona",
        "AO": "Aosta",
        "AP": "Ascoli Piceno",
        "AQ": "L'Aquila",
        "AR": "Arezzo",
        "AT": "Asti",
        "AV": "Avellino",
        "BA": "Bari",
        "BI": "Biella",
        "BL": "Belluno",
        "BN": "Benevento",
        "BO": "Bologna",
        "BR": "Brindisi",
        "BS": "Brescia",
        "BT": "Barletta-Andria-Trani",
        "BZ": "Bolzano",
        "CA": "Cagliari",
        "CB": "Campobasso",
        "CE": "Ceuta",
        "CH": "Chieti",
        "CI": "Carbonia-Iglesias",
        "CL": "Caltanissetta",
        "CN": "Cuneo",
        "CO": "Como",
        "CR": "Cremona",
        "CS": "Cosenza",
        "CT": "Catania",
        "CZ": "Catanzaro",
        "EN": "Enna",
        "FC": "Forlì-Cesena",
        "FE": "Ferrara",
        "FG": "Foggia",
        "FI": "Firenze",
        "FM": "Fermo",
        "FR": "Frosinone",
        "GE": "Genova",
        "GO": "Gorizia",
        "GR": "Grosseto",
        "IM": "Imperia",
        "IS": "Isernia",
        "KR": "Crotone",
        "LC": "Lecco",
        "LE": "Lecce",
        "LI": "Livorno",
        "LO": "Lodi",
        "LT": "Latina",
        "LU": "Lucca",
        "MB": "Monza e Brianza",
        "MC": "Macerata",
        "ME": "Messina",
        "MI": "Milano",
        "MN": "Mantova",
        "MO": "Modena",
        "MS": "Massa-Carrara",
        "MT": "Matera",
        "NA": "Napoli",
        "NO": "Novara",
        "NU": "Nuoro",
        "OG": "Ogliastra",
        "OR": "Oristano",
        "OT": "Olbia-Tempio",
        "PA": "Palermo",
        "PC": "Piacenza",
        "PD": "Padova",
        "PE": "Pescara",
        "PG": "Perugia",
        "PI": "Pisa",
        "PN": "Pordenone",
        "PO": "Prato",
        "PR": "Parma",
        "PT": "Pistoia",
        "PU": "Pesaro e Urbino",
        "PV": "Pavia",
        "PZ": "Potenza",
        "RA": "Ravenna",
        "RC": "Reggio Calabria",
        "RE": "Reggio Emilia",
        "RG": "Ragusa",
        "RI": "Rieti",
        "RM": "Roma",
        "RN": "Rimini",
        "RO": "Rovigo",
        "SA": "Salerno",
        "SI": "Siena",
        "SO": "Sondrio",
        "SP": "La Spezia",
        "SR": "Siracusa",
        "SS": "Sassari",
        "SV": "Savona",
        "TA": "Taranto",
        "TE": "Teramo",
        "TN": "Trento",
        "TO": "Torino",
        "TP": "Trapani",
        "TR": "Terni",
        "TS": "Trieste",
        "TV": "Treviso",
        "UD": "Udine",
        "VA": "Varese",
        "VB": "Verbano-Cusio-Ossola",
        "VC": "Vercelli",
        "VE": "Venezia",
        "VI": "Vicenza",
        "VR": "Verona",
        "VS": "Medio Campidano",
        "VT": "Viterbo",
        "VV": "Vibo Valentia"
    },
    ES: {
        "A": "Alicante",
        "AB": "Alberta",
        "AV": "Ávila",
        "B": "Barcelona",
        "BA": "Badajoz",
        "BI": "Vizcaya",
        "BU": "Burgos",
        "C": "A Coruña",
        "CA": "Cádiz",
        "CC": "Cáceres",
        "CE": "Ceuta",
        "CR": "Ciudad Real",
        "CU": "Cuenca",
        "GC": "Las Palmas",
        "GI": "Gerona",
        "GR": "Granada",
        "GU": "Guadalajara",
        "H": "Huelva",
        "HU": "Huesca",
        "J": "Jaén",
        "L": "Lleida",
        "LE": "León",
        "LO": "La Rioja",
        "LU": "Lugo",
        "M": "Madrid",
        "MA": "Málaga",
        "ML": "Melilla",
        "MU": "Murcia",
        "NA": "Navarra",
        "O": "Asturias",
        "OR": "Ourense",
        "P": "Pontevedra",
        "PM": "Illes Balears",
        "PO": "Girona",
        "S": "Santander",
        "SA": "Salamanca",
        "SE": "Sevilla",
        "SG": "Segovia",
        "SO": "Soria",
        "SS": "Guipúzcoa",
        "T": "Tarragona",
        "TE": "Teruel",
        "TF": "Santa Cruz de Tenerife",
        "TO": "Toledo",
        "V": "Valencia",
        "VA": "Valladolid",
        "VI": "Álava",
        "Z": "Zaragoza",
        "ZA": "Zamora"
    },
    CA: {
        "AB": "Alberta",
        "BC": "British Columbia",
        "MB": "Manitoba",
        "NB": "New Brunswick",
        "NL": "Newfoundland and Labrador",
        "NS": "Nova Scotia",
        "NT": "Northwest Territories",
        "NU": "Nunavut",
        "ON": "Ontario",
        "PE": "Prince Edward Island",
        "QC": "Quebec",
        "SK": "Saskatchewan",
        "YT": "Yukon Territory"
    }
}
