import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'loading-indicator',
    styleUrls: ['./loading.component.scss'],
    template: `
            <div class="d-flex justify-content-center">
              <div class="loading-indicator" ngClass="{{size}} {{color}}">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            </div>
            `,
    standalone: true,
    imports: [NgClass]
})

export class LoadingComponent implements OnInit {



    @Input()
    size: string
    @Input()
    color: string


    constructor() { }

    ngOnInit() {
    }

}
