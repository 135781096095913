import { Injectable, signal, WritableSignal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { environment } from '../../../environments/environment';
import { AWHeaders } from 'shared_models/headers';
import { DashboardUser, LoggedInDashboardUser } from 'shared_models/dashboard-user';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ControlledUser } from 'shared_models/controlled-user';
import { SubCustomerPermission } from 'shared_models/sub-customer';
import { MaintenanceService } from 'src/app/maintenance.service';
import { AccountResponse } from 'shared_models/account';
import { Roles, UserCustomClaims } from 'shared_models/claims';
import * as accessMap from '../auth-guard/roleAccessMap.json';
import { CreateNewCustomer } from 'shared_models/details';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { PreferencesService } from '@services/account/preferences.service';
import { ReauthModalComponent } from '../../components/misc/reauth-modal/reauth-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getAuth, getMultiFactorResolver, multiFactor, MultiFactorError, TotpMultiFactorGenerator } from 'firebase/auth';
import MultiFactorInfo = firebase.auth.MultiFactorInfo;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    storedUser: DashboardUser = this.compareUserToLocalStorage('user') ? this.localStorageService.getItem('user') : null;
    private signedIn = new BehaviorSubject<boolean>(this.storedUser ? true : false); // implemented based on this tut: https://loiane.com/2017/08/angular-hide-navbar-login-page/#example-1-using-ngif-to-hide-the-navbar
    private admin = new BehaviorSubject<boolean>(false); // implemented based on this tut: https://loiane.com/2017/08/angular-hide-navbar-login-page/#example-1-using-ngif-to-hide-the-navbar

    user: DashboardUser | ControlledUser = this.localStorageService.getItem('controlled_user') ? this.localStorageService.getItem('controlled_user') : this.localStorageService.getItem('user'); // is we are contolling a user then persmission is set to that exept admin rights^^^
    private operator = new BehaviorSubject<boolean>(false);
    private developer = new BehaviorSubject<boolean>(false);
    private billingAllowed = new BehaviorSubject<boolean>(false);
    private couponAllowed = new BehaviorSubject<boolean>(false);
    private operatorName = new BehaviorSubject<string>(this.user && this.user.settings && this.user.settings.operator_name ? this.user.settings.operator_name : null); // implemented based on this tut: https://loiane.com/2017/08/angular-hide-navbar-login-page/#example-1-using-ngif-to-hide-the-navbar
    loggedInUser: LoggedInDashboardUser = this.localStorageService.getItem('loggedInUser');
    private role = new BehaviorSubject<Roles>(this.loggedInUser && this.loggedInUser.role ? this.loggedInUser.role : Roles.owner);

    get isSignedIn() {
        return this.signedIn.asObservable();
    }

    get isAdmin() {
        return this.admin.asObservable();
    }

    get isOperator() {
        return this.operator.asObservable();
    }

    get isDeveloper() {
        return this.developer.asObservable();
    }

    get isBillingAllowed() {
        return this.billingAllowed.asObservable();
    }

    get isCouponAllowed() {
        return this.couponAllowed.asObservable();
    }

    get getOperatorName() {
        return this.operatorName.asObservable();
    }

    get getRole() {
        return this.role.asObservable();
    }

    hasMfaEnabled: WritableSignal<boolean> = signal(false);

    baseUrl: string = environment.baseUrl;
    redirectUrl: string =
        window.location.hostname === 'localhost'
            ? 'http://localhost:4200/'
            : environment.firebase.projectId === 'airwallet-685ff'
              ? 'https://admin.airwallet.net/'
              : environment.firebase.projectId === 'airwallettest'
                ? 'https://sandbox.admin.airwallet.net/'
                : 'https://airwallet-staging.firebaseapp.com/';

    constructor(
        private router: Router,
        private http: HttpClient,
        private modalService: NgbModal,
        private firebaseAuth: AngularFireAuth,
        private localStorageService: LocalStorageService,
        private translate: TranslateService,
        private maintenanceService: MaintenanceService,
        private route: ActivatedRoute,
        private preferencesService: PreferencesService,
        private db?: AngularFireDatabase
    ) {}

    async onPageRefresh(): Promise<void> {
        return new Promise<void>(resolve => {
            if (!this.localStorageService.getItem('controlled_user')) {
                firebase.auth().onAuthStateChanged(async (user: firebase.User) => {
                    if (user) {
                        const tokenResult: firebase.auth.IdTokenResult = await user.getIdTokenResult();
                        await this.setPermissionObservers(tokenResult.claims as UserCustomClaims, user.uid);
                    }

                    return resolve();
                });
            } else {
                this.setControlledUid(this.localStorageService.getItem('loggedInUser').uid, true);
                return resolve();
            }
            if (this.user && !!(this.user as DashboardUser).operatorName) {
                this.operatorName.next((this.user as DashboardUser).operatorName);
                return resolve();
            }
        });
    }

    async getBaseHeadersControl(): Promise<Record<string, AWHeaders>> {
        const headers: AWHeaders = {};
        headers['X-Control-Only'] = 'true';
        return { headers: headers };
    }

    async onlyUseBaseHeaders(): Promise<Record<string, AWHeaders>> {
        const headers: AWHeaders = {};
        headers['X-Base-Headers-Only'] = 'true';
        return { headers: headers };
    }

    async addHeaders(onBehalfUid?: string, control?: string): Promise<Record<string, AWHeaders>> {
        const headers: AWHeaders = {};
        if (onBehalfUid) {
            headers.onbehalf = onBehalfUid.split('_operated_by_')[0];
        }
        if (control) {
            headers.controlleduid = control;
        }
        return { headers: headers };
    }

    async getHeadersWithoutOwner(): Promise<Record<string, AWHeaders>> {
        const headers: AWHeaders = {};
        headers['X-Skip-Owner'] = 'true';
        return { headers: headers };
    }

    async setPermissionObservers(claims: UserCustomClaims, uid: string): Promise<void> {
        claims?.role ? this.role.next(claims.role) : this.role.next(Roles.owner);
        claims?.admin ? this.admin.next(true) : this.admin.next(false);
        claims?.developer ? this.developer.next(true) : this.developer.next(false);
        this.operator.next(!!claims?.operator);
        if (claims?.operated) {
            if (claims.owner) {
                uid = claims.owner.split('_operated_by_')[0];
            } else {
                const callbackUrl: string = this.localStorageService.getItem('callbackUrl');
                if (callbackUrl) {
                    let params: object;
                    const routerSub = this.route.queryParams.pipe(take(1)).subscribe(res => {
                        params = res;
                    });

                    routerSub.unsubscribe();
                    this.router.navigate([this.localStorageService.getItem('callbackUrl')], { queryParams: params });
                    this.localStorageService.removeItem('callbackUrl'); // remove callback url after use
                }
            }
            const subCustomerPermissionSub = this.readSubCustomerPermission(`${uid}_operated_by_${claims.operated}`)
                .snapshotChanges()
                .subscribe((subCustomerPermissionSnap: SnapshotAction<SubCustomerPermission>) => {
                    subCustomerPermissionSub.unsubscribe();
                    this.billingAllowed.next(subCustomerPermissionSnap.payload.val()?.allow_billing);
                    this.couponAllowed.next(subCustomerPermissionSnap.payload.val()?.allow_coupon ?? false);
                });
        } else {
            this.billingAllowed.next(true);
            this.couponAllowed.next(true);
        }
    }

    async setControlledUid(controlledUid: string, skipLocalStorage?: boolean) {
        const { user, loggedInUser } = await lastValueFrom(this.http.get(`${environment.baseUrl}/api_support/get_controlled_claims?controlledUid=${controlledUid}`, await this.onlyUseBaseHeaders()))
            .then((res: any) => {
                return res as { user: ControlledUser; loggedInUser: LoggedInDashboardUser };
            })
            .catch(err => {
                return err;
            });

        user.claims.role = loggedInUser.role;

        this.setPermissionObservers(user.claims, controlledUid.split('_operated_by_')[0]);

        if (!skipLocalStorage) {
            //_controlledUser.uid = _controlledUser.uid.split("_operated_by_")[0]
            this.localStorageService.setItem('tempLoggedInUser', this.localStorageService.getItem('loggedInUser'));
            this.localStorageService.setItem('controlled_user', user);
            this.localStorageService.setItem('loggedInUser', loggedInUser);
        }
    }

    async afterSigninProcess(user: firebase.User, skipRouteAction?: boolean): Promise<boolean> {
        return user
            .getIdTokenResult()
            .then(async (tokenResult: any) => {
                await Promise.all([this.preferencesService.loadPreferences(), this.setPermissionObservers(tokenResult.claims, user.uid), this.setLocalStorage(tokenResult, user)]);
                firebase.database().goOnline();
                this.signedIn.next(true);
                if (!skipRouteAction) {
                    this.router.navigate(['/overview']);
                }
                return true;
            })
            .catch(err => {
                console.log('err', err);
                throw err;
            });
    }

    signIn(email: string, pass: string, skipRouteAction?: boolean): Promise<any> {
        return new Promise(async (resolve, reject) => {
            return this.firebaseAuth
                .signInWithEmailAndPassword(email, pass)
                .then(async (res: any) => {
                    const user: firebase.User = res.user;
                    try {
                        await this.afterSigninProcess(user, skipRouteAction);
                        resolve(true);
                    } catch (err) {
                        reject(err);
                    }
                })
                .catch(err => {
                    console.log('err here', err);
                    this.signOut(true);
                    let errorMsg = '';
                    let typeOfError: 'EMAIL' | 'PASSWORD' | 'NO_USER' | 'MFA';
                    switch (err.code) {
                        case 'auth/invalid-email':
                            errorMsg = this.translate.instant('helper.auth.errmsg_email_format');
                            typeOfError = 'EMAIL';
                            break;
                        case 'auth/user-not-found':
                            errorMsg = this.translate.instant('helper.auth.errmsg_email_not_found');
                            typeOfError = 'NO_USER';
                            break;
                        case 'auth/wrong-password':
                            errorMsg = this.translate.instant('helper.auth.errmsg_wrong_password');
                            typeOfError = 'PASSWORD';
                            break;
                        case 'auth/multi-factor-auth-required':
                            errorMsg = this.translate.instant('helper.auth.errmsg_mfa_required');
                            typeOfError = 'MFA';
                            break;
                        default:
                            errorMsg = null;
                            break;
                    }
                    return reject({ errMsg: errorMsg, errType: typeOfError, error: err });
                });
        });
    }

    async setOperatorName(operatorName: string): Promise<void> {
        this.operatorName.next(operatorName);
    }

    async setLocalStorage(tokenResult: firebase.auth.IdTokenResult, firebaseUser: firebase.User): Promise<void> {
        await this.getAccount().then(async (resp: AccountResponse) => {
            const { account, operator_name, allow_billing } = resp;

            this.billingAllowed.next(allow_billing);
            if (!account) {
                this.signOut();
                return;
            }

            const claims: UserCustomClaims = tokenResult.claims as UserCustomClaims;
            const user: DashboardUser = {
                ...firebaseUser,
                uid: resp.owner ? resp.owner : !claims?.operated ? firebaseUser.uid : `${firebaseUser.uid}_operated_by_${claims?.operated}`,
                email: firebaseUser.email,
                settings: {
                    currency: account.default_currency,
                    country: account.country,
                    stripe_region: claims?.stripe_region
                }
            };
            if (operator_name) {
                this.setOperatorName(operator_name);
                user.operatorName = operator_name;
            }
            if (resp.is_regular) {
                user.settings.is_regular = true;
            }
            console.log('user', user);

            this.localStorageService.setItem('user', user);

            const loggedInUser: LoggedInDashboardUser = {
                uid: !claims?.operated || resp.owner ? firebaseUser.uid : `${firebaseUser.uid}_operated_by_${claims?.operated}`,
                name: resp.users_name,
                role: claims.role ? claims.role : Roles.owner,
                email: firebaseUser.email
            };
            this.localStorageService.setItem('loggedInUser', loggedInUser);
            if (resp.owner) {
                this.localStorageService.setItem('owner', resp.owner);
            }
        });
    }

    readSubCustomerPermission(uid: string): AngularFireObject<SubCustomerPermission> {
        return this.db.object(`customers/${uid}/permissions`);
    }

    async getAccount(): Promise<AccountResponse> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${this.baseUrl}/api_account/get_account`))
                .then((resp: any) => {
                    return resolve(resp).data;
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async signOut(skipRouteAction?: boolean, prefillMail?: string): Promise<void> {
        this.signedIn.next(false);
        this.admin.next(false);
        this.operator.next(false);
        this.developer.next(false);
        this.billingAllowed.next(false);
        this.couponAllowed.next(false);
        this.operatorName.next(null);
        firebase.database().goOffline();
        this.firebaseAuth.signOut();
        this.localStorageService.clear();
        this.maintenanceService.setIsUnderMaintenance(undefined);

        if (prefillMail) {
            this.localStorageService.setItem('prefillMail', prefillMail); // trying to access a guarded page, will remember for redirect after sign in.
        }

        if (!skipRouteAction) {
            if (!window.location.pathname.includes('/sign-in')) {
                this.router.navigate([`sign-in`]).then(() => {
                    window.location.reload();
                });
            }
        }
    }

    createUser(createCustomerParams: CreateNewCustomer) {
        const promise = new Promise((resolve, reject) => {
            return firebase
                .auth()
                .signInAnonymously()
                .then(async (anonymousUserCred: firebase.auth.UserCredential) => {
                    return anonymousUserCred.user.getIdToken().then(async () => {
                        return lastValueFrom(
                            this.http.post(
                                `${environment.baseUrl}/api_account`,
                                {
                                    ...createCustomerParams,
                                    anonymousUid: anonymousUserCred.user.uid
                                } as CreateNewCustomer,
                                {}
                            )
                        )
                            .then(async () => {
                                this.localStorageService.removeItem('returnUrl');
                                return this.signIn(createCustomerParams.email, createCustomerParams.password)
                                    .then(value => {
                                        resolve(value);
                                    })
                                    .catch(async err => {
                                        return reject(err);
                                    });
                            })
                            .catch(err => {
                                return reject(err);
                            });
                    });
                })
                .catch(err => {
                    return reject(err);
                });
        });

        return Promise.resolve(promise);
    }

    async reAuthenticate(reAuth: { reAuthEmail: string; reAuthPassword: string }) {
        return this.firebaseAuth.signInWithEmailAndPassword(reAuth.reAuthEmail, reAuth.reAuthPassword);
    }

    async handleReauthentication(text: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const modalRef = this.modalService.open(ReauthModalComponent);
            modalRef.componentInstance.text = text; // Pass the text value here
            modalRef.result.then(
                result => {
                    console.log('result', result);
                    if (result === 'success') {
                        resolve();
                    } else {
                        reject(new Error('Re-authentication failed'));
                    }
                },
                reason => {
                    reject(new Error('Re-authentication dismissed'));
                }
            );
        });
    }

    async handleMfa(error: MultiFactorError, otp: string): Promise<void> {
        console.log('error', error, 'otp', otp);
        const mfaResolver = getMultiFactorResolver(getAuth(), error);

        const totpHint: MultiFactorInfo = mfaResolver.hints.find(hint => hint.factorId === TotpMultiFactorGenerator.FACTOR_ID);
        if (totpHint) {
            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpHint.uid, otp);
            try {
                await mfaResolver.resolveSignIn(multiFactorAssertion);
                return;
            } catch (error) {
                console.error('Error handling MFA:', error);
                throw error; // Invalid or expired OTP.
            }
        } else {
            throw new Error('No TOTP factor found.');
        }
    }

    async checkMfaStatus(): Promise<void> {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const mfaInfo = multiFactor(user).enrolledFactors;
            const totpEnrollment = mfaInfo.find(factor => factor.factorId === 'totp');

            if (totpEnrollment) {
                this.hasMfaEnabled.set(true);
            }
        } else {
            console.error('No user is currently signed in.');
        }
    }

    resetPassword(email: string) {
        return this.firebaseAuth.sendPasswordResetEmail(email.replace(/\s/g, '').toLowerCase(), { url: `${this.redirectUrl}/sign-in` });
    }

    async verifyEmail(email: string): Promise<void> {
        const promise = new Promise<void>((resolve, reject) => {
            const options = {
                params: {
                    email: email
                }
            };
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_account/auth_verify_email`, options))
                .then(() => {
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });

        return Promise.resolve(promise) as Promise<void>;
    }

    compareUserToLocalStorage(itemName: 'user'): boolean {
        const localItem: any = this.localStorageService.getItem(itemName);
        if (localItem === null) {
            return false;
        }

        function instanceOfUser(object: DashboardUser | ControlledUser): object is DashboardUser | ControlledUser {
            return object && 'uid' in object && 'email' in object && 'settings' in object;
        }

        return instanceOfUser(localItem);
    }

    allowAccess(route: string, role: Roles): boolean {
        if (role === Roles.owner) return true;

        return accessMap[role].includes(route);
    }

    hasLimitedAccess(route: string, role: Roles): boolean {
        if (role === Roles.owner) return true;

        return !accessMap[`${role}_no_access`].includes(route);
    }

    async getAdminStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.isAdmin.subscribe({
                next: (admin: boolean) => {
                    resolve(admin);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
        });
    }

    async getSignedInStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.isSignedIn.subscribe({
                next: (isSignedIn: boolean) => {
                    resolve(isSignedIn);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
        });
    }

    async getOperatorStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const operatorSub = this.isOperator.subscribe({
                next: (operator$: boolean) => {
                    resolve(operator$);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
            operatorSub.unsubscribe();
        });
    }

    async getDeveloperStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const developerSub = this.isDeveloper.subscribe({
                next: (developer: boolean) => {
                    resolve(developer);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
            developerSub.unsubscribe();
        });
    }

    async getRoleStatus(): Promise<Roles> {
        return new Promise<Roles>((resolve, reject) => {
            const roleSub = this.role.subscribe({
                next: (role: Roles) => {
                    resolve(role);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
            roleSub.unsubscribe();
        });
    }

    async getCouponStatus(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const couponSub = this.isCouponAllowed.subscribe({
                next: (couponAllowed: boolean) => {
                    resolve(couponAllowed);
                },
                error: (error: unknown) => {
                    reject(error);
                }
            });
            couponSub.unsubscribe();
        });
    }
}
