import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵAPP_CHECK_PROVIDER_NAME as _APP_CHECK_PROVIDER_NAME, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, ɵAppCheckInstances as _AppCheckInstances, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵzoneWrap as _zoneWrap } from '@angular/fire';
export { ɵAppCheckInstances as AppCheckInstances } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, isDevMode, Optional, PLATFORM_ID, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { registerVersion } from 'firebase/app';
import { getLimitedUseToken as getLimitedUseToken$1, getToken as getToken$1, initializeAppCheck as initializeAppCheck$1, onTokenChanged as onTokenChanged$1, setTokenAutoRefreshEnabled as setTokenAutoRefreshEnabled$1 } from 'firebase/app-check';
export * from 'firebase/app-check';
class AppCheck {
  constructor(appCheck) {
    return appCheck;
  }
}
const appCheckInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(_APP_CHECK_PROVIDER_NAME))), distinct());
const PROVIDED_APP_CHECK_INSTANCES = new InjectionToken('angularfire2.app-check-instances');
function defaultAppCheckInstanceFactory(provided, defaultApp) {
  const defaultAppCheck = _getDefaultInstanceOf(_APP_CHECK_PROVIDER_NAME, provided, defaultApp);
  return defaultAppCheck && new AppCheck(defaultAppCheck);
}
const LOCALHOSTS = ['localhost', '0.0.0.0', '127.0.0.1'];
const isLocalhost = typeof window !== 'undefined' && LOCALHOSTS.includes(window.location.hostname);
function appCheckInstanceFactory(fn) {
  return (zone, injector, platformId) => {
    // Node should use admin token provider, browser devmode and localhost should use debug token
    if (!isPlatformServer(platformId) && (isDevMode() || isLocalhost)) {
      globalThis.FIREBASE_APPCHECK_DEBUG_TOKEN ??= true;
    }
    const appCheck = zone.runOutsideAngular(() => fn(injector));
    return new AppCheck(appCheck);
  };
}
const APP_CHECK_INSTANCES_PROVIDER = {
  provide: _AppCheckInstances,
  deps: [[new Optional(), PROVIDED_APP_CHECK_INSTANCES]]
};
const DEFAULT_APP_CHECK_INSTANCE_PROVIDER = {
  provide: AppCheck,
  useFactory: defaultAppCheckInstanceFactory,
  deps: [[new Optional(), PROVIDED_APP_CHECK_INSTANCES], FirebaseApp, PLATFORM_ID]
};
class AppCheckModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'app-check');
  }
  static ɵfac = function AppCheckModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AppCheckModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AppCheckModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_APP_CHECK_INSTANCE_PROVIDER, APP_CHECK_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppCheckModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_APP_CHECK_INSTANCE_PROVIDER, APP_CHECK_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function provideAppCheck(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'app-check');
  return makeEnvironmentProviders([DEFAULT_APP_CHECK_INSTANCE_PROVIDER, APP_CHECK_INSTANCES_PROVIDER, {
    provide: PROVIDED_APP_CHECK_INSTANCES,
    useFactory: appCheckInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, PLATFORM_ID, _AngularFireSchedulers, FirebaseApps, ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const getLimitedUseToken = _zoneWrap(getLimitedUseToken$1, true);
const getToken = _zoneWrap(getToken$1, true);
const initializeAppCheck = _zoneWrap(initializeAppCheck$1, true);
const onTokenChanged = _zoneWrap(onTokenChanged$1, true);
const setTokenAutoRefreshEnabled = _zoneWrap(setTokenAutoRefreshEnabled$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { AppCheck, AppCheckModule, appCheckInstance$, getLimitedUseToken, getToken, initializeAppCheck, onTokenChanged, provideAppCheck, setTokenAutoRefreshEnabled };
