import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { AntonHealthTableSettings } from '@services/local-storage/local-storage.models';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { SupportedLanguages } from '../../constants/supportedLanguages';

@Injectable({
    providedIn: 'root'
})
export class PreferencesService {
    baseUrl: string = environment.baseUrl;

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService
    ) {}

    async setAntonHealthColumnPreferences(settings: AntonHealthTableSettings): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${this.baseUrl}/api_account/anton_health_column_preferences`, JSON.stringify(settings)))
                .then((resp: any) => {
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async loadPreferences(): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${this.baseUrl}/api_account/preferences`))
                .then((resp: any) => {
                    if (resp != null) {
                        const newPreferences = {
                            columns: []
                        };
                        //Preferences for anton health column selections
                        Object.entries(resp.columnPreferences ?? {}).forEach(value => {
                            newPreferences.columns.push({
                                key: value[0],
                                show: value[1]
                            });
                        });

                        //Add the two last columns that are not in the preferences, to make sure table calculates width correctly.
                        newPreferences.columns.push({ key: '', show: true }, { key: '', show: true });
                        this.localStorageService.setItem('antonHealthTableSettings', newPreferences);
                        //Preferences for language selection
                        const language = resp.languagePreferences ? SupportedLanguages.filter(language => language.language_code === resp.languagePreferences)[0] : { language_code: 'en', name: 'English' };
                        this.localStorageService.setItem('language', language);
                        this.translateService.use(language.language_code);
                    }

                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async setLanguagePreferences(language: string): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${this.baseUrl}/api_account/language_preferences`, { language }))
                .then((resp: any) => {
                    return resolve();
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
