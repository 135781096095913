import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

export interface Language {
  language_code: string,
  name: string,
}


export const SupportedLanguages: Language[] = [
  { language_code: 'en', name: 'English' },
  { language_code: 'da', name: 'Danish' },
  { language_code: 'fr', name: 'French' },
  { language_code: 'de', name: 'German' },
  { language_code: 'it', name: 'Italian' },
  { language_code: 'no', name: 'Norwegian' },
  { language_code: 'pl', name: 'Polish' },
  { language_code: 'es', name: 'Spanish' },
]



export const locales = {
  en: () => import('dayjs/locale/en'),
  en_gb: () => import('dayjs/locale/en-gb'),
  da: () => import('dayjs/locale/da'),
  nl: () => import('dayjs/locale/nl'),
  fi: () => import('dayjs/locale/fi'),
  fr: () => import('dayjs/locale/fr'),
  de: () => import('dayjs/locale/de'),
  it: () => import('dayjs/locale/it'),
  no: () => import('dayjs/locale/nn'),
  pl: () => import('dayjs/locale/pl'),
  es: () => import('dayjs/locale/es'),
}




// Current supported languages
// English
// Danish
// Dutch
// Finnish
// French
// German
// Italian
// Norwegian
// Polish
// Spanish