import { Component, ElementRef, EventEmitter, Output, viewChild, viewChildren } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';

/**
 * @description
 *
 * This component is a custom OTP input component that allows users to input their OTP in a more user-friendly way.
 * Some styling sits in the global styling, since we are manipulating the html directly, we have to use the global styling to style the component.
 *
 * The html is set up in a way, that we have a single input, which the user types into (They cant see this input)
 * Instead of showing the values in inputs, we show them in our own div inputs.
 *
 * So the values is split into 6 boxes, and the user can only type 6 characters.
 *
 * The program handles remove and adding of numbers and even checks if the what is typed is an input.
 *
 * The component also handles pasting, removing any non-number characters and only allowing numbers to be pasted.
 * Works in a way that the user can paste the OTP and it will be split into the boxes.
 *
 *
 */

@Component({
    selector: 'app-aw-otp-input',
    standalone: true,
    imports: [NgOtpInputModule],
    templateUrl: './aw-otp-input.component.html',
    styleUrl: './aw-otp-input.component.scss'
})
export class AwOtpInputComponent {
    @Output() otpComplete: EventEmitter<string> = new EventEmitter<string>();
    inputs = viewChildren<ElementRef>('inputs');
    inputEl = viewChild<ElementRef>('input');
    activeHtml = '<div class="otp-box active"><span>|</span><div>';
    nonActiveHtml = (value: string) => {
        return `<div class="otp-box">${value}<div>`;
    };
    input = [];
    currentIndex = 0;
    maxLength = 6;

    onFocus() {
        const elements = this.inputs();

        // Use current index to start from where the user left off
        if (this.currentIndex !== this.maxLength) {
            const div = elements[this.currentIndex].nativeElement as HTMLDivElement;
            div.innerHTML = this.activeHtml;
            div.classList.toggle('active', true);
        } else {
            elements[this.maxLength - 1].nativeElement.innerHTML = this.nonActiveHtml(this.input[this.maxLength - 1]);
        }
    }

    onBlur() {
        const elements = this.inputs();
        if (this.currentIndex !== this.maxLength) {
            const div = elements[this.currentIndex].nativeElement as HTMLDivElement;
            div.textContent = '';
            div.classList.toggle('active', false);
        } else {
            elements[this.maxLength - 1].nativeElement.textContent = this.input[this.maxLength - 1];
        }
    }

    onPaste(event: ClipboardEvent) {
        event.preventDefault();

        const clipboardData = event.clipboardData || (window as any).clipboardData;
        const pastedText = clipboardData.getData('text');

        // Check if the pasted text is a number and not a string
        if (isNaN(Number(pastedText))) {
            return;
        }
        const elements = this.inputs();
        this.input = pastedText.split('');
        this.currentIndex = this.input.length;

        // Used to set the value of the input to the input array
        for (let i = 0; i < elements.length; i++) {
            const div = elements[i].nativeElement as HTMLDivElement;
            if (i < this.input.length) {
                div.textContent = this.input[i];
            } else {
                div.textContent = '';
            }

            // actives div if its the current index
            div.classList.toggle('active', i === this.currentIndex);
            if (i === this.currentIndex) {
                div.innerHTML = this.activeHtml;
            }
        }

        if (this.currentIndex === this.maxLength) {
            elements[this.maxLength - 1].nativeElement.innerHTML = this.nonActiveHtml(this.input[this.maxLength - 1]);
        }

        // We have to set the value of the input to the input array, since we prevented the default event, we have to set the value manually
        this.inputEl().nativeElement.value = this.input.join('');

        if (this.input.length === 6) {
            this.otpComplete.emit(this.input.join(''));
        }
    }

    onInputChange(event: InputEvent) {
        const target = event.target as HTMLInputElement;
        const elements = this.inputs();
        target.value = target.value.replace(/\s/g, '');
        // Checks if the last character is a number and that it is not empty, then we remove that char and dont accept it
        if (isNaN(Number(target.value.charAt(target.value.length - 1))) && target.value !== '') {
            target.value = target.value.slice(0, target.value.length - 1);
            return;
        }

        // First check if length of input is more than inputs which means a new number
        if (target.value.length > this.input.length) {
            this.input = target.value.split('');
            this.currentIndex = this.input.length;
            for (let i = 0; i < elements.length; i++) {
                const div = elements[i].nativeElement as HTMLDivElement;
                if (i < this.input.length) {
                    div.textContent = this.input[i];
                } else {
                    div.textContent = '';
                }
                div.classList.toggle('active', i === this.currentIndex);

                if (i === this.currentIndex) {
                    div.innerHTML = this.activeHtml;
                }
            }
            if (this.currentIndex === this.maxLength) {
                elements[this.maxLength - 1].nativeElement.innerHTML = this.nonActiveHtml(this.input[this.maxLength - 1]);
            }
        } else if (target.value.length < this.input.length) {
            // Remove the value from the input, if the new target (input) is less than what we have, then the user just removed one.
            const element = elements[this.input.length];
            if (element) {
                const divBefore = element.nativeElement as HTMLDivElement;
                divBefore.classList.toggle('active', false);
                divBefore.textContent = '';
            }
            if (this.currentIndex === this.maxLength) {
                elements[this.maxLength - 1].nativeElement.textContent = this.input[this.maxLength - 1];
            }
            this.input.pop();
            this.currentIndex = this.input.length;
            const div = elements[this.input.length].nativeElement as HTMLDivElement;
            div.innerHTML = this.activeHtml;
            div.classList.toggle('active', this.currentIndex === this.input.length);
        }

        if (this.input.length === 6) {
            this.otpComplete.emit(this.input.join(''));
        }
    }
}
