{
    "editor": [
        "overview",
        "transactions",
        "locations",
        "account",
        "account/:onboarding",
        "team-members",
        "developer",
        "billing",
        "stripelink",
        "settlements",
        "locations/:location_id",
        "locations/:location_id/devices/:device_id",
        "locations/:location_id/device_setup/:reconfig_values",
        "locations/:location_id/device_setup",
        "users",
        "users/:user_id",
        "operator",
        "actions",
        "contracts",
        "devices",
        "operator/customers",
        "operator/anton-health",
        "operator/devices",
        "operator/contracts",
        "operator/actions",
        "anton-health",
        "customers",
        "customers/create",
        "customers/:sub_customer_id/locations",
        "customers/:sub_customer_id/locations/:location_id",
        "customers/:sub_customer_id/locations/:location_id/devices/:device_id",
        "customers/:sub_customer_id/locations/:location_id/device_setup",
        "customers/:sub_customer_id/locations/:location_id/device_setup/:reconfig_values",
        "coupon",
        "coupon/coupon-create",
        "coupon/:key",
        "search"
    ],
    "editor_no_access": ["transactions", "account", "search"],
    "technician": [
        "locations",
        "account",
        "billing",
        "locations/:location_id",
        "locations/:location_id/devices/:device_id",
        "locations/:location_id/device_setup/:reconfig_values",
        "locations/:location_id/device_setup",
        "operator",
        "customers",
        "actions",
        "operator/customers",
        "operator/anton-health",
        "operator/actions",
        "operator/devices",
        "anton-health",
        "customers/:sub_customer_id/locations",
        "customers/:sub_customer_id/locations/:location_id",
        "customers/:sub_customer_id/locations/:location_id/devices/:device_id",
        "customers/:sub_customer_id/locations/:location_id/device_setup/:reconfig_values",
        "customers/:sub_customer_id/locations/:location_id/device_setup",
        "devices",
        "customers/create",
        "search"
    ],
    "technician_no_access": ["control_panel", "location_stats", "device_stats", "limited_customers_page", "operator_filter_bar", "all_devices_stats", "accounting_email", "coupon", "search"],
    "user": [
        "overview",
        "transactions",
        "locations",
        "account",
        "settlements",
        "billing",
        "account/:onboarding",
        "locations/:location_id",
        "locations/:location_id/devices/:device_id",
        "locations/:location_id/device_setup/:reconfig_values",
        "locations/:location_id/device_setup",
        "operator",
        "anton-health",
        "actions",
        "contracts",
        "devices",
        "operator/anton-health",
        "operator/customers",
        "operator/actions",
        "operator/contracts",
        "operator/devices",
        "customers",
        "customers/create",
        "customers/:sub_customer_id/locations",
        "customers/:sub_customer_id/locations/:location_id",
        "customers/:sub_customer_id/locations/:location_id/devices/:device_id",
        "customers/:sub_customer_id/locations/:location_id/device_setup",
        "customers/:sub_customer_id/locations/:location_id/device_setup/:reconfig_values",
        "users",
        "users/:user_id",
        "coupon",
        "coupon/coupon-create",
        "coupon/:key",
        "search"
    ],
    "user_no_access": [
        "create_location",
        "create_units",
        "location_settings",
        "booking_settings",
        "control_panel_revenue_share",
        "control_panel_permissions",
        "control_panel_settings",
        "edit_device",
        "create_customer",
        "create_contract",
        "edit_contract",
        "close_action",
        "edit_kyc",
        "edit_terminal",
        "search"
    ]
}
