@if (week() && !arrayIsEmpty) {
<div class="input-group-wrapper">
    <div class="form-control status no-border">
        <div class="week-wrapper">
            <div class="title">
                <span class="title-text">{{'location.selected_weekdays' | translate}}</span>
                <u class="pointer force-top text-box-fs" (click)="edit.emit(true)">{{'coupon.edit' |
                    translate}}</u>
            </div>
            @for (day of objToArray(week(), "byDay"); track day[0]) {
            <div class="day-style text-box-fs" *ngIf="hasEvents(day[0])">
                <span class="day-label">{{ 'misc.weekdays.'+day[0]+ (longFormattedDays ? '.long' : '.short') |
                    translate}}:</span>
                <div class="ms-1">
                    @for (event of objToArray(week()[day[0]], "byTime"); track event[0];) {
                    <div class="event" style="display: grid; grid-template-columns: 2fr 1fr 2fr">
                        <p>{{ addSemiColonToTime(event[1].start, false) }}</p>
                        <p> → </p>
                        <p>{{ addSemiColonToTime(event[1].end, true) }}</p>
                    </div>
                    }
                </div>

            </div>
            }
        </div>
    </div>

</div>
} @else {
<div class="input-group-wrapper">
    <div class="form-control status no-border">
        <span class="title-text">{{'location.choose_specific_hours' | translate}}</span>
        <u [classList]="disableLayout() ? 'force-top text-box-fs disabled' : 'pointer force-top text-box-fs'"
            (click)="disableLayout() ? '' : edit.emit(true)">{{'coupon.edit' |
            translate}}</u>
    </div>
</div>
}