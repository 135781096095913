import {Component, ElementRef, Input, NgZone, OnInit, Signal, viewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import { NgIf } from '@angular/common';
import { take } from 'rxjs';

@Component({
    selector: 'app-custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss'],
    standalone: true,
    imports: [NgIf]
})

export class CustomModalComponent implements OnInit {
    @Input() activeModal: NgbModalRef;

    element: Signal<ElementRef | undefined> = viewChild.required<ElementRef>('element');
    constructor(
        public modalService: NgbModal,
        private ngZone: NgZone
    ) { }

    ngOnInit(): void {
        this.ngZone.onStable.asObservable().pipe(take(1)).subscribe(() => {
            const element = this.element().nativeElement;
            if (element) {
                element.focus();
            }
        });
    }

    dismissModal(): void {
        if(this.activeModal) {
            this.activeModal.close();
        } else {
            this.modalService.dismissAll()
        }
    }

}