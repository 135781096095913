<section #element class="modal-grid">
    <div class="header">
        <div class="modal-header-inner-wrapper">
            <div class="overflow-container">
                <ng-content select="[modal-title]"></ng-content>
            </div>
            <div class="dismiss" style="text-align: right">
                <div #dismiss>
                    <ng-content select="[modal-dismiss]"></ng-content>
                </div>
                <ng-container *ngIf="!dismiss.children.length">
                    <a tabindex="undefined" (keyup.enter)="dismissModal()" (click)="dismissModal()"
                        ><img src="../../../../../assets/icons/modal-close-icon.svg" alt="close dialog"
                    /></a>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="body">
        <ng-content select="[modal-body]"></ng-content>
    </div>
    <div class="footer">
        <ng-content select="[modal-footer]"></ng-content>
    </div>
</section>
