export enum Brand {
    ADC = 'ADC',
    AEG = 'AEG',
    ASKO = 'ASKO',
    Bosch = 'BOSCH',
    Danube = 'DANUBE',
    Dexter = 'DEXTER',
    Domus = 'DOMUS',
    Electrolux = 'ELECTROLUX',
    Encore = 'ENCORE',
    Fagor = 'FAGOR',
    GE = 'GE',
    Girbau = 'BIRBAU',
    Huebsch = 'HUEBSCH',
    IPSO = 'IPSO',
    Kenmore = 'KENMORE',
    LaundryAllianceSystems = 'LAUNDRY_ALLIANCE_SYSTEMS',
    LG = 'LG',
    Maytag = 'MAYTAG',
    Merker = 'MERKER',
    Miele = 'MIELE',
    Milnor = 'MILNOR',
    Panasonic = 'PANASONIC',
    Primer = 'PRIMER',
    Samsung = 'SAMSUNG',
    Sanyo = 'SANYO',
    Schulthess = 'SCHULTHESS',
    SpeedQueen = 'SPEED_QUEEN',
    Unimac = 'UNIMAC',
    Whirlpool = 'WHIRLPOOL',
    Zanussi = 'ZANUSSI',
    Other = 'OTHER'
}
