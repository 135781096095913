import { getApps as getApps$1, getApp as getApp$1, registerVersion as registerVersion$1, deleteApp as deleteApp$1, initializeApp as initializeApp$1, initializeServerApp as initializeServerApp$1, onLog as onLog$1, setLogLevel as setLogLevel$1 } from 'firebase/app';
export * from 'firebase/app';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, PLATFORM_ID, VERSION as VERSION$1, NgModule, Inject, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵzoneWrap as _zoneWrap } from '@angular/fire';
class FirebaseApp {
  constructor(app) {
    return app;
  }
}
class FirebaseApps {
  constructor() {
    return getApps$1();
  }
}
const firebaseApp$ = timer(0, 300).pipe(concatMap(() => from(getApps$1())), distinct());
function defaultFirebaseAppFactory(provided) {
  // Use the provided app, if there is only one, otherwise fetch the default app
  if (provided && provided.length === 1) {
    return provided[0];
  }
  return new FirebaseApp(getApp$1());
}
// With FIREBASE_APPS I wanted to capture the default app instance, if it is initialized by
// the reserved URL; ɵPROVIDED_FIREBASE_APPS is not for public consumption and serves to ensure that all
// provideFirebaseApp(...) calls are satisfied before FirebaseApp$ or FirebaseApp is resolved
const PROVIDED_FIREBASE_APPS = new InjectionToken('angularfire2._apps');
// Injecting FirebaseApp will now only inject the default Firebase App
// this allows allows beginners to import /__/firebase/init.js to auto initialize Firebase App
// from the reserved URL.
const DEFAULT_FIREBASE_APP_PROVIDER = {
  provide: FirebaseApp,
  useFactory: defaultFirebaseAppFactory,
  deps: [[new Optional(), PROVIDED_FIREBASE_APPS]]
};
const FIREBASE_APPS_PROVIDER = {
  provide: FirebaseApps,
  deps: [[new Optional(), PROVIDED_FIREBASE_APPS]]
};
function firebaseAppFactory(fn) {
  return (zone, injector) => {
    const platformId = injector.get(PLATFORM_ID);
    registerVersion$1('angularfire', VERSION.full, 'core');
    registerVersion$1('angularfire', VERSION.full, 'app');
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    registerVersion$1('angular', VERSION$1.full, platformId.toString());
    const app = zone.runOutsideAngular(() => fn(injector));
    return new FirebaseApp(app);
  };
}
class FirebaseAppModule {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(platformId) {
    registerVersion$1('angularfire', VERSION.full, 'core');
    registerVersion$1('angularfire', VERSION.full, 'app');
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    registerVersion$1('angular', VERSION$1.full, platformId.toString());
  }
  static ɵfac = function FirebaseAppModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || FirebaseAppModule)(i0.ɵɵinject(PLATFORM_ID));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FirebaseAppModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_FIREBASE_APP_PROVIDER, FIREBASE_APPS_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FirebaseAppModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_FIREBASE_APP_PROVIDER, FIREBASE_APPS_PROVIDER]
    }]
  }], () => [{
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }], null);
})();
// Calling initializeApp({ ... }, 'name') multiple times will add more FirebaseApps into the FIREBASE_APPS
// injection scope. This allows developers to more easily work with multiple Firebase Applications. Downside
// is that DI for app name and options doesn't really make sense anymore.
function provideFirebaseApp(fn, ...deps) {
  return makeEnvironmentProviders([DEFAULT_FIREBASE_APP_PROVIDER, FIREBASE_APPS_PROVIDER, {
    provide: PROVIDED_FIREBASE_APPS,
    useFactory: firebaseAppFactory(fn),
    multi: true,
    deps: [NgZone, Injector, _AngularFireSchedulers, ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const deleteApp = _zoneWrap(deleteApp$1, true);
const getApp = _zoneWrap(getApp$1, true);
const getApps = _zoneWrap(getApps$1, true);
const initializeApp = _zoneWrap(initializeApp$1, true);
const initializeServerApp = _zoneWrap(initializeServerApp$1, true);
const onLog = _zoneWrap(onLog$1, true);
const registerVersion = _zoneWrap(registerVersion$1, true);
const setLogLevel = _zoneWrap(setLogLevel$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { FirebaseApp, FirebaseAppModule, FirebaseApps, deleteApp, firebaseApp$, getApp, getApps, initializeApp, initializeServerApp, onLog, provideFirebaseApp, registerVersion, setLogLevel };
