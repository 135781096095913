import { KeyValuePipe, NgIf } from '@angular/common';
import { Component, input, InputSignal, OnChanges, OnInit, output, OutputEmitterRef, SimpleChanges } from '@angular/core';
import { LoadingComponent } from '@components/loading/loading.component';
import { Week } from '@shared_models/location';
import { TranslateModule } from '@ngx-translate/core';
import { HelperService } from '@services/helper/helper.service';
import { DashboardUser } from '@shared_models/dashboard-user';

@Component({
    selector: 'app-aw-calendar-layout',
    standalone: true,
    imports: [NgIf, LoadingComponent, KeyValuePipe, TranslateModule],
    templateUrl: './aw-calendar-layout.component.html',
    styleUrl: './aw-calendar-layout.component.scss'
})
export class AwCalendarLayoutComponent implements OnChanges, OnInit {
    week: InputSignal<Week> = input<Week>();
    longFormattedDays: InputSignal<boolean> = input<boolean>(true);
    edit: OutputEmitterRef<boolean> = output<boolean>();
    disableLayout: InputSignal<boolean> = input<boolean>(false);
    user: DashboardUser;
    arrayIsEmpty = true;
    constructor(private helperService: HelperService) {}
    ngOnInit(): void {
        this.user = this.helperService.getUser();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.week && changes.week.currentValue) {
            for (const day in this.week()) {
                const event = this.week()[day];

                if (event && Object.keys(event).length > 0) {
                    this.arrayIsEmpty = false;
                    break;
                } else {
                    this.arrayIsEmpty = true;
                }
            }

            this.arrayIsEmpty = Object.keys(changes.week.currentValue).length === 0;
        }
    }

    // Key value correct week days
    objToArray(obj: object, sort: 'byDay' | 'byTime') {
        const firstDay = this.user.settings.country === 'US' ? 0 : 1;
        const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

        if (firstDay === 0) {
            // Sunday is first day, so move sunday to last
            days.unshift(days.shift());
        }
        if (obj) {
            const arr = Object.keys(obj).map(key => [key, obj[key]]);
            if (sort === 'byDay') {
                arr.sort((a, b) => {
                    return days.indexOf(a[0]) - days.indexOf(b[0]);
                });
            } else {
                arr.sort((a, b) => {
                    return a[1].start + a[1].end - (b[1].start + b[1].end);
                });
            }

            return arr;
        }
    }

    addSemiColonToTime(time: string, end: boolean) {
        if (time === '2400') {
            time = '2359';
        }

        if (end && time === '0000') {
            time = '2359';
        }

        return time.replace(/(\d{2})(\d{2})/, '$1:$2');
    }

    hasEvents(day: string) {
        return this.week()[day] && Object.keys(this.week()[day]).length > 0;
    }
}
