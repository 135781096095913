<app-custom-modal>
  <ng-container modal-title>
    @if (mfaOtpEnabled) {
      <h4>{{ "account.mfa.two_factor_capitalized" | translate }}</h4>
    } @else {
      <h4>{{ "account.confirm_account" | translate }}</h4>
    }
  </ng-container>
  <ng-container modal-body>
    <div>
      @if (reAuthForm && !mfaOtpEnabled) {
        <p>{{ text }}</p>
        <div [formGroup]="reAuthForm" class="mb-3">
          <label for="reAuthEmail" class="titleInputTransaction">{{ "misc.email" | translate }}</label>
          <input id="reAuthEmail" formControlName="reAuthEmail" class="form-control" type="email" name="reAuthEmail" />
          <div *ngIf="reAuthFormSubmitted && reAuthEmail.invalid">
            <div class="error-msg" *ngIf="reAuthEmail.errors.required">
              {{ "misc.email_required" | translate }}
            </div>
          </div>
          <label for="reAuthPassword" class="titleInputTransaction">Password</label>
          <input id="reAuthPassword" formControlName="reAuthPassword" class="form-control" type="password"
                 name="password" />
          <div *ngIf="reAuthFormSubmitted && reAuthPassword.invalid">
            <div class="error-msg" *ngIf="reAuthPassword.errors.required">
              {{ "account.password_required" | translate }}
            </div>
          </div>
        </div>
      }
      @if (mfaOtpEnabled) {
        <div class="otp-box">
          <p>{{ "account.mfa.enter_passcode" | translate }}</p>
          <div class="otp-box-input">
            <app-aw-otp-input (otpComplete)="catchOtpEvent($event)"></app-aw-otp-input>
          </div>
        </div>
      }
    </div>
  </ng-container>
  <ng-container modal-footer>
    <div class="d-grid gap-2">
      @if (mfaOtpEnabled) {
        <button class="btn btn-secondary" tabindex="-1" [disabled]="loadingMfaCheck"
                (click)="handleMfaOtp()">
          @if (loadingMfaCheck) {
            <loading-indicator [size]="'tiny'" [color]="'white'"></loading-indicator>
          } @else {
            <span>{{ "account.mfa.verify" | translate }}</span>
          }
        </button>
      } @else {
        <button class="btn btn-secondary" tabindex="-1" [disabled]="reAuthFormValidated"
                (click)="confirmReAuth()">
          <span>{{ "sign-in.confirm" | translate }}</span>
        </button>
      }
    </div>
  </ng-container>
</app-custom-modal>
