import { Component, Input, OnInit } from '@angular/core';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth/auth.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from '@services/helper/helper.service';
import { MultiFactorError } from 'firebase/auth';
import { AwOtpInputComponent } from '@components/misc/aw-otp-input/aw-otp-input.component';
import { LoadingComponent } from '@components/loading/loading.component';

@Component({
    selector: 'app-reauth-modal',
    standalone: true,
    imports: [CustomModalComponent, FormsModule, NgIf, ReactiveFormsModule, TranslateModule, AwOtpInputComponent, LoadingComponent],
    templateUrl: './reauth-modal.component.html',
    styleUrl: './reauth-modal.component.scss'
})
export class ReauthModalComponent implements OnInit {
    @Input() text: string;

    reAuthFormSubmitted = false;
    reAuthFormValidated = false;
    reAuthForm: UntypedFormGroup;

    mfaOtpEnabled = false;
    mfaError: MultiFactorError;
    loadingMfaCheck = false;

    otpCode = '';

    constructor(
        protected translate: TranslateService,
        private formBuilder: UntypedFormBuilder,
        private authService: AuthService,
        public activeModal: NgbActiveModal,
        protected modalService: NgbModal,
        private helperService: HelperService
    ) {}

    ngOnInit() {
        this.setupReAuthForm();
    }

    setupReAuthForm() {
        const user = this.helperService.getUser();
        this.reAuthForm = this.formBuilder.group({
            reAuthEmail: new UntypedFormControl(user.email, [Validators.required]),
            reAuthPassword: new UntypedFormControl(null, [Validators.required])
        });
    }

    async confirmReAuth() {
        this.reAuthFormSubmitted = true;
        if (this.reAuthForm.valid) {
            const reAuthValues = this.reAuthForm.value;
            try {
                await this.authService.reAuthenticate(reAuthValues);
                this.reAuthFormValidated = true;
                this.activeModal.close('success'); // Pass the re-auth values back
            } catch (err) {
                if (err?.code === 'auth/too-many-requests') {
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.too_many_attempts'), 'Warning');
                } else if (err?.code === 'auth/multi-factor-auth-required') {
                    this.mfaError = err;
                    this.toggleMfaOtp();
                } else {
                    console.error(err);
                    this.helperService.defaultHtmlToast('', this.translate.instant('account.reauth_error'), 'Error');
                }
            }
        }
    }

    async catchOtpEvent(otp: string) {
        this.otpCode = otp;
        await this.handleMfaOtp();
    }

    async handleMfaOtp() {
        this.loadingMfaCheck = true;
        await this.authService
            .handleMfa(this.mfaError, this.otpCode)
            .then(() => {
                this.activeModal.close('success'); // Pass the re-auth values back
            })
            .catch(() => {
                this.helperService.defaultHtmlToast('', this.translate.instant('account.mfa.invalid_mfa'), 'Error');
            })
            .finally(() => {
                this.loadingMfaCheck = false;
            });
    }

    toggleMfaOtp() {
        this.mfaOtpEnabled = !this.mfaOtpEnabled;
    }

    get reAuthEmail() {
        return this.reAuthForm.get('reAuthEmail');
    }

    get reAuthPassword() {
        return this.reAuthForm.get('reAuthPassword');
    }
}
