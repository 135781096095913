
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { ShowToast } from '../../../../shared_models/show-toast'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";

export enum RequestTypes {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}

export interface Options {
    params?: Record<string, string | number | boolean>,
    body?: any,
    onBehalf?: string,
    control?: string,
}

@Injectable({
    providedIn: 'root'
})

export class HttpService {

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private toast: ToastrService,
        private translate: TranslateService,
    ) { }

    async dynamicHttp<T>(path: string, RequestType: RequestTypes, options: Options = {}): Promise<T> {
        switch (RequestType) {
            case RequestTypes.GET:
                return new Promise(async (resolve: any, reject: any) => {
                    return lastValueFrom(this.http.get(
                        `${environment.baseUrl}/${path}${getUrlParams(options)}`,
                        await this.authService.addHeaders(options.onBehalf, options.control)
                    )).then((res) => {                        
                        return resolve(res)
                    }).catch((e) => {
                        this.catchToast(e)
                        return reject(e);
                    })
                })

            case RequestTypes.POST:
                return new Promise(async (resolve: any, reject: any) => {
                    return lastValueFrom(this.http.post(
                        `${environment.baseUrl}/${path}${getUrlParams(options)}`,
                        options && options.body ? options.body : null,
                        await this.authService.addHeaders(options.onBehalf, options.control)
                    )).then((res) => {
                        return resolve(res)
                    }).catch((e) => {
                        this.catchToast(e)
                        return reject(e);
                    })
                })

            case RequestTypes.PUT:
                return new Promise(async (resolve: any, reject: any) => {
                    return lastValueFrom(this.http.put(
                        `${environment.baseUrl}/${path}${getUrlParams(options)}`,
                        options && options.body ? options.body : null,
                        await this.authService.addHeaders(options.onBehalf, options.control)
                    )).then((res) => {
                        return resolve(res)
                    }).catch((e) => {
                        this.catchToast(e)
                        return reject(e);
                    })
                })

            case RequestTypes.DELETE:
                return new Promise(async (resolve: any, reject: any) => {
                    const headers: any = await this.authService.addHeaders(options.onBehalf, options.control)
                    const requestOptions = {
                        headers: headers['headers'],
                        body: options && options.body ? options.body : null
                    }
                    return lastValueFrom(this.http.delete(
                        `${environment.baseUrl}/${path}${getUrlParams(options)}`,
                        requestOptions
                    )).then((res) => {
                        return resolve(res)
                    }).catch((e) => {
                        this.catchToast(e)
                        return reject(e);
                    })
                })
        }
    }

    catchToast(e: any) {
        console.log(e);

        if (e.error && e.error.error && e.error.error.showToast) {
            const showToast: ShowToast = e.error.error.showToast
            switch (showToast.type) {
                case 'error':
                    this.toast.error(this.translate.instant(showToast.description), this.translate.instant(showToast.title ? showToast.title : 'misc.error'));
                    break;
                case 'succes':
                    this.toast.success(this.translate.instant(showToast.description), this.translate.instant(showToast.title ? showToast.title : 'misc.success'));
                    break;
                case 'warning':
                    this.toast.warning(this.translate.instant(showToast.description), this.translate.instant(showToast.title ? showToast.title : 'misc.warning'));
                    break;
                case 'info':
                    this.toast.info(this.translate.instant(showToast.description), this.translate.instant(showToast.title ? showToast.title : 'misc.info'));
                    break;
                default:
                    break;
            }
        }
    }
}


function getUrlParams(options: Options): string {
    if (options && options.params) {
        const params = [];
        for (const key in options.params) {
            if (options.params.hasOwnProperty(key)) {
                const value = options.params[key];
                if (value !== null && value !== undefined) {
                    params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`);
                }
            }
        }
        return params ? `?${params.join('&')}` : '';
    } else {
        return ''
    }
}