import { Injectable } from '@angular/core';
import { LocalObjectData } from './local-storage.models';
/**
 * Service for managing local storage operations.
 * Provides methods to set, get, and remove items from local storage.
 * The items are stored in a local object and synchronized with the browser's local storage.
 */
@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private _localObject: LocalObjectData = {};

    private objectKeys: Record<keyof LocalObjectData, 'string' | 'object'> = {
        callbackUrl: 'string',
        passwordSet: 'string',
        antonHealthTableSettings: 'object',
        language: 'object',
        controlled_user: 'object',
        user: 'object',
        loggedInUser: 'object',
        tempLoggedInUser: 'object',
        prefillMail: 'string',
        reconfigValues: 'object',
        returnUrl: 'string',
        owner: 'string'
    };
    constructor() {
        this.loadFromLocalStorage();
    }

    private loadFromLocalStorage(): void {
        const keys = Object.keys(this.objectKeys);
        for (const key of keys) {
            const value = this.objectKeys[key];
            const item = localStorage.getItem(key);
            if (item) {
                this._localObject[key] = value === 'object' ? JSON.parse(item) : item;
            }
        }
    }
    /**
     * Sets an item in local storage.
     * @param key The key of the item to set. Must be one of the keys of LocalObject.
     * @param value The value to set. The type must match the type defined in LocalObject for the given key.
     */
    public setItem<T extends keyof LocalObjectData>(key: T, value: LocalObjectData[T]): void {
        this._localObject[key] = value;
        localStorage.setItem(key, this.objectKeys[key] === 'object' ? JSON.stringify(value) : (value as string));
    }
    /**
     * Gets an item from local storage.
     * @param key The key of the item to get. Must be one of the keys of LocalObject.
     * @returns The value of the item, or null if the item does not exist.
     */
    public getItem<T extends keyof LocalObjectData>(key: T): LocalObjectData[T] | null {
        const item = localStorage.getItem(key);
        if (this._localObject[key]) {
            return this._localObject[key];
        } else if (item) {
            return this.objectKeys[key] === 'object' ? JSON.parse(item) : item;
        } else {
            return null;
        }
    }
    /**
     * Removes an item from local storage.
     * @param key The key of the item to remove. Must be one of the keys of LocalObject.
     */
    public removeItem<T extends keyof LocalObjectData>(key: T): void {
        delete this._localObject[key];
        localStorage.removeItem(key);
    }

    public clear(): void {
        this._localObject = {};
        localStorage.clear();
    }
}
