export interface Coupon {
    key: string;
    name: string;
    redemptions: number;
    owner_uid: string;
    creation_date: number;
    discount_type: DiscountType;
    percentage_discount?: number;
    fixed_amount?: number;
    free_cycles?: number;
    currency?: string;
    distribute_type: DistributeType;
    coupon_code?: string;
    entries?: {
        users: {
            count: number;
            limit?: number;
        };
    };
    duration: DurationDetails;
    notify_users: boolean;
    notification_title: string;
    notification_description: string;
    global: boolean;
    description: string; //JSON string with translaton objects
    // applied_to?: Array<CustomerDisplay>;
    archived: boolean;
    tracking_details?: DashboardCoupon;
}

export interface LocationCouponRef {
    coupon_key: string;
    coupon_owner_uid: string;
    location_key: string;
    location_owner_uid: string;
}

export interface CustomerCoupons {
    location?: Record<string, Coupon>;
    global?: Record<string, Coupon>;
}

type createCouponTypesOmitted = 'owner_uid' | 'key' | 'creation_date' | 'redemptions' | 'archived' | 'tracking_details' | 'description';
export interface CreateCouponParams {
    coupon: Omit<Coupon, createCouponTypesOmitted>;
    appliedCustomerLocations?: CustomerLocations[];
}

export interface CustomerLocations {
    customerUid: string;
    customerName: string;
    locationId: string;
    locationName: string;
}

export interface DurationDetails {
    type: DurationType;
    from?: number;
    to?: number;
    triggerType?: TriggerType;
    target?: number;
    given_up_front?: boolean;
    happy_hours?: HappyHours;
    refill_target?: number;
}

export interface ExtendedLocation {
    id: string;
    selected?: boolean;
    name: string;
    currency: string;
}

export interface CustomerDisplay {
    uid: string;
    isExpanded: boolean;
    locations: Array<ExtendedLocation>;
    selectAllLocations?: boolean;
    name: string;
    isOwner: boolean;
}

export interface CouponRef {
    coupon_key: string;
    coupon_owner_uid: string;
    location_key: string;
    location_owner_uid: string;
}
export interface CouponCodeRef {
    key: string; // coupon key
    owner_uid: string; // owner of the coupon
    path: string; // path to the coupon
    code: string; // coupon code
}

export interface CouponAppliedTo {
    type: 'all' | 'specific';
    specified?: Record<
        string, //This is going to be customerUid
        Record<
            string, // This will always be locationId
            true
        >
    >;
}

export interface HappyHours {
    [day: string]: {
        [key: string]: {
            start: string;
            end: string;
        };
    };
}

export enum DiscountType {
    PERCENTAGE = 'percentage',
    FIXED = 'fixed',
    CYCLES = 'freeCycles'
}

export enum DistributeType {
    ALL = 'all',
    SELECTED = 'selected',
    CODE = 'code',
    VOUCHER = 'voucher'
}

export enum DurationType {
    FOREVER = 'forever',
    ONCE = 'once',
    PERIOD = 'period',
    EVERY = 'every',
    HAPPYHOUR = 'happyHour',
    REFILL = 'refill'
}

export enum TriggerType {
    STARTS = 'starts',
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months',
    SPENT = 'spent'
}

export enum CouponSortParams {
    NAME = 'name',
    REDEMPTIONS = 'redemptions',
    EXPIRE = 'expire',
    CREATED = 'created'
}

// Happy hour event types
export interface Event {
    start: string;
    end: string;
} // e.g. from 18.00 - 21.45 is start: '1800' and end: '2145', 5.30 in the morning would be '0530' etc..

export enum Weekdays {
    SUN = 'sun', // is 0
    MON = 'mon',
    TUE = 'tue',
    WED = 'wed',
    THU = 'thu',
    FRI = 'fri',
    SAT = 'sat'
}

export type Events = {
    [key in Weekdays]?: Record<
        string, // this is a six-digit random str
        Event
    >;
};

// `coupon/user_tracking/${user_uid}/${coupon_key}`
export interface UserCouponTracking {
    archived: boolean;
    type: DiscountType;
    owner_uid: string;
    trigger_type?: TriggerType;
    coupon_redemptions: number; // amount of times the coupon has been used
    cycles_since_used: number; // keep track of how many cycles the user has made since last time the coupon was used
    amount_since_used: number; // keep track of how much the user has spent since last time the coupon was used
    usable_amount: number; // On coupon with fixed amount, this amount will be decreased wwhen used
    usable_cycles: number;
    earned_usable_amount: number;
    earned_usable_cycles: number;
    target_spent_amount: number;
    target_cycles_used: number;
}

export enum CouponStatus {
    EXPIRED = 'expired', // Coupon has expired or was already used
    VALID = 'valid', // Coupon is valid and but is not usable right now
    ACTIVE = 'active', // Coupon is active and can be used now
    NOT_APPLIED = 'not_applied' // coupon is not expired and user does not have access to it
}

export interface DashboardCoupon extends AppCoupon {
    distribute_type: DistributeType;
    owner_uid: string;
    user_uid?: string;
}

export interface AppCoupon {
    key: string;
    name: string;
    description: string; //JSON string with translaton objects
    status: CouponStatus;
    deletable: boolean;
    remaining_amount?: number;
    remaining_cycles?: number;
    amount_since_used?: number;
    cycles_since_used?: number;
    target_amount?: number;
    target_cycles?: number;
}

export interface GetCouponResponse {
    coupons_to_use: Record<number, string>; // Key is the original amount and value is the coupon key for that amount
    adjusted_amounts: Record<number, number>; // Key is the original amount and value is the adjusted amount
    validation_keys: Record<string, string>; // Key is the coupon key and value is the validation key
    coupons: DashboardCoupon[]; // Array of all coupons in a model that the app can display easily
}
// // For app
// export interface GetCouponResponse {
//     coupon_to_use?: string;
//     orginal_amount: number;
//     adjusted_amount: number;
//     saved_amount: number;
//     validation_key?: string;
//     coupon_available: boolean;
// }

export interface CouponValidation {
    coupon_key: string;
    original_amount: number;
    adjusted_amount: number;
    expire: number;
}

// Type guard to check if an object is a Coupon
export function isCoupon(obj: any): obj is Coupon {
    return 'name' in obj && 'entries' in obj && 'redemptions' in obj && 'creation_date' in obj;
}
