<ng-template #calendarModal let modal>
    <app-custom-modal>
        <ng-container modal-title>
            <p class="aw-calendar-title">
                {{ modalTitle() }}
            </p>
        </ng-container>
        <ng-container modal-dismiss>
            <a tabindex="undefined" (keyup.enter)="closeModal()" (click)="closeModal()">
                <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" />
            </a>
        </ng-container>
        <ng-container modal-body>
            <div style="display: grid">
                <u class="pointer force-top text-box-fs btn-link" style="justify-self: flex-end" (click)="cleanAll()">{{ "misc.clear" | translate }}</u>
            </div>
            <div id="calendar"></div>
        </ng-container>
        <ng-container modal-footer>
            <div tabindex="-1" class="d-flex flex-row align-items-center justify-content-between" *ngIf="isMobile">
                <button class="btn btn-danger btn-tiny mb-3 mt-3" [disabled]="!selectedEventId" (click)="removeEvent()" style="width: 48%">
                    {{ isLoading ? "" : ("coupon.remove_timeslot" | translate) }}
                    <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                </button>
                <button class="btn btn-secondary btn-tiny mb-3 mt-3" (click)="saveSelections()" style="width: 48%">
                    {{ isLoading ? "" : ("location.save_opening_hours" | translate) }}
                    <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                </button>
            </div>
            <div tabindex="-1" class="d-flex flex-column align-items-center happy-hour-save-btn" *ngIf="!isMobile">
                <button class="btn btn-secondary mt-3" (click)="saveSelections()" style="width: 45%">
                    {{ isLoading ? "" : ("location.save_opening_hours" | translate) }}
                    <loading-indicator [size]="'small'" [color]="'white'" *ngIf="isLoading"></loading-indicator>
                </button>
            </div>
        </ng-container>
    </app-custom-modal>
</ng-template>
