import { StripeRegions } from './stripe';

export enum Claims {
    lookup = 'lookup', // only used to lookup roles (read only)
    admin = 'admin',
    operator = 'operator',
    developer = 'developer',
    operated = 'operated', // this claim is only set by backend logic (i.e. not part of support tool as other claims are)
    stripe_region = 'stripe_region'
}

export enum Roles {
    editor = 'editor',
    owner = 'owner',
    technician = 'technician',
    user = 'user',
    bookkeeper = 'bookkeeper'
}

export interface UserCustomClaims {
    stripe_region: StripeRegions;
    operated?: string;
    admin?: boolean;
    developer?: boolean;
    operator?: boolean;
    owner?: string;
    role?: Roles;
}
